$primary-color: #1958a8;
$second-color: #005eb8;
$third-color: #00266e;

$rgb-primary-color: (red($primary-color), green($primary-color), blue($primary-color)); //--primary-color *
$rgb-second-color: (red($second-color), green($second-color), blue($second-color)); //--second-color *
$rgb-third-color: (red($third-color), green($third-color), blue($third-color)); //--third-color *

@import "../../../src/styles/vars.scss";

@import "../../../src/styles/styles.scss";

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

html {
    scroll-behavior: smooth;
}
body.webapp-site {
	--font-title: 'Raleway', sans-serif;
    --font-subtext: 'Raleway', sans-serif;
    --font-text: 'Raleway', sans-serif;
	background-color: #f6f9f9;
    scroll-behavior: smooth;
    .login__form-logo, .register__form-logo {
        max-height: 120px;
    }
    // General
    #cttMain {
        position: relative;
        z-index: 1;
        min-height: 50vh;
    }
    .dropdown_menu_cy-item {
        .dropdown_menu_cy-action {
            font-family: var(--font-title);
        }
    }

    .cy__btn.mdc-button.btn__third {
        color: white;
    }

	.custom__card-1 {
        padding: 1rem;
		border: 1px solid #ddd;
		border-radius: 10px;
		margin: 10px;
        .content__img {
            width: 100%;
            display: block;
            border-radius: 8px;
            background-color: rgba($third-color, 0.2);
            &.default__ctt-image {
                min-height: 210px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin: auto;
                    border-radius: 0;
                }
				&.type_2 {
					width: fit-content;
					margin: 0 auto;
				}
            }
            img {
                width: 100%;
                display: block;
                border-radius: 8px;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .subtext__card {
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 10px;
            }
            .title__card {
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 10px;
            }
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
        .content__btns {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
			.btn_buy {
				background-color: var(--second-color);
				color: #fff;
				text-transform: uppercase;
				font-family: var(--font-title);
			}
        }
    }

	.custom__card-select {
        padding: 1rem;
        border: 2px solid #EAECF0;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        user-select: none;
        &.active {
            pointer-events: none;
            cursor: default;
            border-color: var(--second-color);
            background-color: var(--second-color);
			color: white;
            .content__head span {
                border-color: var(--third-color);
                &::before {
                    transform: scale(1);
					background-color: var(--primary-color);
                }
            }
        }
        .content__head {
            width: 100%;
            display: grid;
            grid-template-columns: minmax(0,20px) minmax(0,1fr);
            gap: 10px;
            align-items: center;
            span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #EAECF0;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--second-color);
                    transition: all ease-in-out 0.2s;
                    transform: scale(0);
                    transform-origin: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .title__card {
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                margin: 0;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
    }

	.social__auth {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		.btn__outline.cy__btn {
			min-width: unset;
			border-radius: 50%;
			padding: 0;
			width: 50px;
			height: 50px;
			margin: 5px !important;
			.mdc-button__label .span__text {
				display: none;
			}
		}
	}

	.register__profile {
		background-color: #fff;
	}

	.profile__settings {
        background-color: #fff;
        .title__ps, .current__profile-ps {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-family: var(--font-title);
        }
		.cy__btn.mdc-button.btn__change-profile {
			border-color: var(--primary-color);
			background-color: var(--primary-color);
			color: #fff;
        }
		.cy__btn.mdc-button.btn__register-profile {
			border-color: var(--second-color);
			background-color: var(--second-color);
			color: #fff;
		}
    }
    .process_cart {
        background-color: #fff;
        .title__pc, .subtitle__pc {
            color: var(--third-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-family: var(--font-title);
        }
        .card__pc {
            border-radius: 0.75rem;
        }
        .order__pc {
            border: 1px solid #e5e7eb;
            border-radius: 0.75rem;
            table {
                th {
                    background-color: #f9fafb;
                    border-bottom: 1px solid #e5e7eb;
                }
                td {
                    border-bottom: 1px solid #e5e7eb;
                }
            }
        }
        .order-total__pc {
            border-radius: 0.75rem;
            color: var(--third-color);
            background-color: #EAECF0;
            font-weight: 500;
        }
        .cy__btn.mdc-button.btn__pay, .cy__btn.mdc-button.btn__pc, .cy__btn.mdc-button.btn__add-card, .cy__btn.mdc-button.btn__invoice {
            background-color: var(--primary-color);
			border-color: var(--primary-color);
            color: #fff;
        }
    }

	.content__pcs {
        width: 100%;
		overflow: hidden;
        .content__step-pcs {
            width: 100%;
			display: grid;
			align-items: center;
			grid-template-columns: minmax(0,max-content) minmax(0,1fr);
            &.active__step {
                .icon__step-pcs {
					&::before {
						content: "";
						background-color: var(--second-color);
					}
				}
            }
            .icon__step-pcs {
				align-self: flex-start;
				position: relative;
				&::before {
					content: "";
					background-color: #eaecf0;
					transform: translateX(-50%);
					width: 2px;
					height: 200px;
					left: 50%;
					position: absolute;
				}
                .ctt__icon-pcs {
                    background-color: #fff;
                    margin-bottom: 10px;
                }
            }
			.body__step-pcs {
				margin-left: 0.5rem;
				color: var(--second-color);
				.title__pcs {
					font-size: .875rem;
					line-height: 1.25rem;
				}
			}
        }
    }

    .payments {
        background-color: #fff;
        .title__payments {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            font-family: var(--font-title);
        }
    }

    .card-general {
        .btns__card-account {
            text-align: left;
        }
    }

	.content__payment-card, .content__payment-card-double-sided, .payment-card-add {
		--color-card-payment-text: var(--second-color);
	}

	.content__card-section-2 {
		min-height: 210px;
		display: flex;
		flex-direction: column;
		.space__card-h {
			flex: 1;
		}
	}

	.content__card-section-3 {
		border-radius: 0 !important;
		border-bottom-left-radius: 10px !important;
		border-bottom-right-radius: 10px !important;
		border-top: 4px solid var(--primary-color) !important;
		padding: 1.5rem;
		.cy__btn.mdc-button.btn__primary {
			width: 100%;
			background-color: var(--second-color);
			border-color: var(--second-color);
			color: white;
		}
	}

	.custom__card-2 {
		display: flex;
		flex-direction: column;
		.content__head {
			flex: 1;
		}
	}

	.content__payments {
		.content__body {
			padding: 0 10px;
		}
	}

    .content__tickets-by-sector-header {
        .content__tickets-by-sector-title {
            p {
                font-family: var(--font-title);
            }
        }
    }

    .content__shop .content__shop-events .shop__events-item {
        background-color: rgba(var(--rgb-primary-color), 0.9);
    }

    .logos__footer-border {
        border-color: white;
    }
    .copyright__footer-border {
        border: 0;
    }

    .copyright__footer {
        color: white;
    }

    .sup__footer-contain {
        background-color: rgba(var(--rgb-primary-color),0.6);
    }
    footer.footer__contain {
        background-color: var(--primary-color);
    }

    header {
        .dropdown_menu_cy-item .dropdown_menu_cy-action {
            color: black;
        }
        ul li a, .text-secondary {
            color: black;
        }
    }
    .sidebar_left {
        ul li a, .text-secondary {
            color: var(--third-color);
        }
    }

    .sidebar__acquire {
        // .text-secondary {
        //     color: var(--primary-color);
        // }
        .content__pcs .content__step-pcs {
            .body__step-pcs {
                color: var(--primary-color);
            }
            &.active__step .icon__step-pcs::before {
                background-color: var(--primary-color);
            }
        }
    }
    .process_cart {
        .title__pc {
            color: var(--primary-color);
        }

        .order__pc {
            color: black;
        }
    }

    .ctt__images-fixture-section::after {
        background-color: var(--primary-color);
    }
    .ctt__fixture-section {
        .text-secondary {
            color: var(--primary-color);
        }
    }

    .content__info-renov {
        color: white;
        background-color: rgba(var(--rgb-primary-color), 0.6);
    }

    .home__section-1, .home__section-2, .home__section-3, .home__section-4, .home__section-5 {
        .text-secondary {
            color: var(--primary-color);
        }
    }

    .content__swiper {
        .nav__slider {
            color: var(--primary-color);
        }
        .pagination__slider .swiper-pagination-bullet {
            background-color: var(--primary-color);
        }
    }

    .content__card-section-2 .action__btn {
        color: white;
        &:hover {
            color: var(--second-color);
        }
    }

    //* Responsive

	@media screen and (max-width: 1279px) {
		.content__pcs {
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			overflow: hidden;
			max-width: 100%;
			.content__step-pcs {
				width: auto;
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				.icon__step-pcs {
					align-self: center;
					&::before {
						display: none;
					}
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.content__pcs .content__step-pcs {
			border: 1px solid #ddd;
			border-radius: 10px;
			margin: 5px;
			width: calc(100% - (5px * 2));
		}
	}
	@media screen and (max-width: 639px) {
		.profile__settings {
			padding: 1rem !important;
		}
	}
}
